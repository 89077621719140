import { defineStore } from 'pinia'


export const useEquipos =  defineStore('equipos', {
    state: () => ({
        myTeams: null,
        fullTeams: null,
        getFullMyTeams:null,
        getFullTeams:null,
        equiposTab: [
            { id: 'Mis equipos', name:'equipos', class:'xs:w-[162px]' },
            { id: 'Todos los equipos', name:'equipos1', class:'xs:w-[162px]'},
            
        ],
    }),


    getters: {
        getLocalStorageMyTeams: (state) => (nameLocal)=> {
            return state.myTeams.Teams.forEach(element => {
                const storedValue = localStorage.getItem(`${nameLocal}_${element.TeamName}`);
                        
                element.isOpen = (storedValue !== null ? JSON.parse(storedValue) : element.TeamName == "Diseño" ? true : false);
            
            });
           
        },

        getLocalStorageFullTeams: (state) => (nameLocal) => {
            return state.fullTeams.Teams.forEach(element => {
                const storedValue = localStorage.getItem(`${nameLocal}_${element.TeamName}`);
                        
                element.isOpen = (storedValue !== null ? JSON.parse(storedValue) : element.TeamName == "Diseño" ? true : false);
            
            });
           
        },

        getListMyTeams: (state) => {
            if (state.getFullMyTeams == null) {
                state.getLocalStorageMyTeams('isOpenMyTeams')
                return state.myTeams;
            }

            if (state.getFullMyTeams != null) {
                state.getLocalStorageMyTeams('isOpenMyTeams')
                return state.getFullMyTeams;
            }
           
        },

        getListFullTeams: (state) => {
            if (state.getFullTeams == null) {
                state.getLocalStorageFullTeams('isOpenFullTeams')
                return state.fullTeams;
            }

            if (state.getFullTeams != null) {
                state.getLocalStorageFullTeams('isOpenFullTeams')
                return state.getFullTeams;
            }
           
        },
          

    },


    actions: {

        filterMyTeams(searchParamsTeam, selectedArea, selectedMarca) {
            // Inicialmente, todos los equipos
            let filteredTeams = this.myTeams.Teams;
          
            // Filtrar por Área (si se selecciona)
            if (selectedArea && selectedArea !== "Areas no encontrado") {
              filteredTeams = filteredTeams.filter((team) => team.TeamName.toLowerCase() === selectedArea.toLowerCase());
            }
          
            // Filtrar por Marca (si se selecciona)
            if (selectedMarca && selectedMarca !== "Marcas no encontrado") {
              filteredTeams = filteredTeams.map((team) => ({
                ...team,
                Members: team.Members.filter((member) => member.ClientName === selectedMarca)
              }));
            }
          
            // Filtrar por búsqueda (si se ingresa algo)
            if (searchParamsTeam && searchParamsTeam.trim() !== "") {
              const searchQuery = searchParamsTeam.toLowerCase().trim();
              filteredTeams = filteredTeams.map((team) => {
                const filteredMembers = team.Members.filter((member) => {
                  const lowerClientName = member.ClientName.toLowerCase();
                  const lowerFirstname = member.Firstname.toLowerCase();
                  const lowerLastname = member.Lastname.toLowerCase();
                  const lowerProjectName = member.ProjectName.toLowerCase();
                  const lowerTaskNames = member.Tasks.map((task) => task.TaskName.toLowerCase());
                  const lowerTeamName = team.TeamName.toLowerCase();
          
                  return (
                    lowerClientName.includes(searchQuery) ||
                    lowerFirstname.includes(searchQuery) ||
                    lowerLastname.includes(searchQuery) ||
                    lowerProjectName.includes(searchQuery) ||
                    lowerTaskNames.some((taskName) => taskName.includes(searchQuery)) ||
                    lowerTeamName.includes(searchQuery)
                  );
                });
          
                return {
                  ...team,
                  Members: filteredMembers
                };
              });
            }
          
            // Eliminar equipos sin miembros
            filteredTeams = filteredTeams.filter((team) => team.Members.length > 0);
            if(filteredTeams.length == 0) return this.myTeams.Teams
            return filteredTeams;
        },

        filterFullTeams(searchParamsTeam, selectedArea, selectedMarca) {
            // Inicialmente, todos los equipos
            let filteredTeams = this.fullTeams.Teams;
          
            // Filtrar por Área (si se selecciona)
            if (selectedArea && selectedArea !== "Areas no encontrado") {
              filteredTeams = filteredTeams.filter((team) => team.TeamName.toLowerCase() === selectedArea.toLowerCase());
            }
          
            // Filtrar por Marca (si se selecciona)
            if (selectedMarca && selectedMarca !== "Marcas no encontrado") {
              filteredTeams = filteredTeams.map((team) => ({
                ...team,
                Members: team.Members.filter((member) => member.ClientName === selectedMarca)
              }));
            }
          
            // Filtrar por búsqueda (si se ingresa algo)
            if (searchParamsTeam && searchParamsTeam.trim() !== "") {
              const searchQuery = searchParamsTeam.toLowerCase().trim();
              filteredTeams = filteredTeams.map((team) => {
                const filteredMembers = team.Members.filter((member) => {
                  const lowerClientName = member.ClientName.toLowerCase();
                  const lowerFirstname = member.Firstname.toLowerCase();
                  const lowerLastname = member.Lastname.toLowerCase();
                  const lowerProjectName = member.ProjectName.toLowerCase();
                  const lowerTaskNames = member.Tasks.map((task) => task.TaskName.toLowerCase());
                  const lowerTeamName = team.TeamName.toLowerCase();
          
                  return (
                    lowerClientName.includes(searchQuery) ||
                    lowerFirstname.includes(searchQuery) ||
                    lowerLastname.includes(searchQuery) ||
                    lowerProjectName.includes(searchQuery) ||
                    lowerTaskNames.some((taskName) => taskName.includes(searchQuery)) ||
                    lowerTeamName.includes(searchQuery)
                  );
                });
          
                return {
                  ...team,
                  Members: filteredMembers
                };
              });
            }
          
            // Eliminar equipos sin miembros
            filteredTeams = filteredTeams.filter((team) => team.Members.length > 0);
            if(filteredTeams.length == 0) return this.fullTeams.Teams
            return filteredTeams;
        },
          
        

        toogleOpenMyTeams(index) {
          
            const fullTeams = index.TeamName;
            index.isOpen = !index.isOpen;
          
            if ( process.client) {
              localStorage.setItem(`isOpenMyTeams_${fullTeams}`, JSON.stringify(index.isOpen));
            }
           
        },

     
        toogleOpenFullTeams(index) {
         
            const fullTeams = index.TeamName;
            index.isOpen = !index.isOpen;
          
            if ( process.client) {
              localStorage.setItem(`isOpenFullTeams_${fullTeams}`, JSON.stringify(index.isOpen));
            }
           
        },
          
          
          

    },


})

