<template>
    <div class="flex flex-col">
         <div class="flex flex-col w-full flex-wrap items-start mt-[27px]" v-for="(item, index) in props.settingsCard?.data" :key="index">
            
            <div :class ="`flex flex-col w-full ${ itemTeams.isOpen == false? ' mb-[20px]':''}` " v-for="(itemTeams, indexTeam) in item" :key="indexTeam">
				
                <div class="flex w-full  items-center py-[8px] bg-[#0A47ED] rounded-[6px] cursor-pointer" @click="toogleOpen(itemTeams)">
               
					<p class="font-WT w-full text-white xs:text-[14px] lg:text-[16px] font-[600] capitalize pl-[19px] mb-[0]"> {{ itemTeams.TeamName}}</p>
					<img src="~/assets/images/arrow/icon-arrow-w.svg" :class="`cursor-pointer mr-[20px] w-[12px] h-[8px] ${itemTeams.isOpen ? '' : 'rotate-[180deg]'}`" alt="WundermanThompson" width="8" height="4" />
                </div>
      
          
                <div  v-if="itemTeams.isOpen == true" class="flex flex-wrap w-full" >
                
                    <div class="cards xs:w-full xs:mr-[0] md:w-[47.3%] md:mr-[19px] md:even:mr-[0] lg:w-[31.5%] lg:even:mr-[19px]  xl:w-[31.5%] bg-[#F8F8F8] last:mr-[0] mb-[20px] pb-[21px] rounded-[9px] mt-[27px]" v-for="(itemMembers, indexMembers) in itemTeams.Members" :key="indexMembers">
				
                        <div class="flex items-center justify-between px-[19px] border-b-[1px] border-solid w-full pb-[11px] pt-[13px]">
                            <div class="flex items-center">
                                <p class="font-WT xs:text-[12px] lg:text-[14px] flex justify-center items-center xs:p-[15px] xs:w-[26px] xs:h-[26px] 2xl:w-[33px] 2xl:h-[33px] bg-[#0A47ED] text-[#FAFAFA] rounded-full">
                                    {{ itemMembers.Firstname[0] +  itemMembers.Lastname[0] }}
                                </p>
                            
                                <p class="font-WT text-[#212322] font-[600] xs:text-[14px] lg:text-[16px] ml-[12px]">{{ itemMembers.Firstname +  ' ' +itemMembers.Lastname  }}</p>
                            </div>
                        
                            <span :class="`flex w-[12px] h-[12px] rounded-full ${(itemMembers.ProjectName == '' || itemMembers.ProjectName ) && (itemMembers.Tasks.length == 0) ? 'bg-[#00D1D9]': 'bg-[#FF760C]'}` "></span>
                        </div> 
            
                        <div class="flex flex-col ml-[20px] mt-[13px] lg:pr-[10px]">
                            <p class="font-WT text-[#0A47ED] xs:text-[10px] lg:text-[14px] font-[600] ">Proyecto:</p>
                            <p class="font-WT text-[#212322] xs:text-[10px] lg:text-[14px]">{{ itemMembers.ProjectName }}</p>
                        </div>

						<p class="mt-[7px] ml-[20px] font-WT text-[#0A47ED] xs:text-[10px] lg:text-[14px] font-[600] ">Tarea:</p>
                        <div class="flex flex-col ml-[20px] lg:pr-[10px] " v-for="(itemTask, indexTaks) in itemMembers.Tasks" :key="indexTaks">
                            <p class="font-WT text-[#212322] xs:text-[10px] lg:text-[14px] whitespace-pre-line">{{ itemTask.TaskName }}</p>
                        </div>
                    </div>
                
                    
                </div>
            </div>
           
          
         
            
        </div>
    </div>
</template>
<script setup>
   
    import InfiniteLoading from "v3-infinite-loading";
    import "v3-infinite-loading/lib/style.css";
   

    import { useEquipos} from '~/store/equipos/index';


    import useLoadApi from '~/composables/useLoadApi';

    const storeEquipos = useEquipos();

    const props = defineProps({ 
    
        settingsCard: {  type:Array }
    });


    const toogleOpen = (index) => {
        switch (props.settingsCard.nameTab) {
            case "mis equipos":
                storeEquipos.toogleOpenMyTeams(index)
                break;
            case "todos los equipos":
                storeEquipos.toogleOpenFullTeams(index)
                break;
        
        
            default:
                break;
        }
  
       
    }



    // const load = ($state) => {
    //    
    //     // Obtener las tareas de la variable `item`
     
    //     const areasToLoadObject = clientes.areas;
    //     const areasToLoadArray = Object.entries(areasToLoadObject);
      

    //     // Verificar si ya se cargaron todas las tareas

    //     if (areasToLoadArray.length === 0) {
    //         $state.complete();
    //         return;
    //     }

    //     const chunkSize = 4;
    //     const areasToLoad = areasToLoadArray.splice(0, chunkSize);

    //     if (areasToLoadArray.length === 0) {
    //         $state.complete();
    //     } else {
    //         $state.loaded();
    //     }
      
     

    // };

</script>
<style scoped lang="scss">
    /* Estilos de Tailwind CSS */

    /* Para los elementos que sean múltiplos de 3, aplica un margen derecho de cero */
    .cards:nth-child(3n) {
        @apply lg:mr-[0] 
    }


</style>
